import React, { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import NoInterview from '../../../../../assets/images/no_ai_interview.png'
import { makeRequest } from "../../../common/RequestAssist/RequestAssist"
import moment from 'moment'

import InterviewCreatePage from './InterviewCreatePage'
import InviteInterviewPage from './InviteInterviewPage'

const Index = (prop) => {
    // interview create module
    const [interviewModalShow, setInterviewModalShow] = useState(prop.showDefaultAddModal ? true : false)
    const [showSuccessInterviewCreate, setShowSuccessInterviewCreate] = useState(false)
    const [interviews, setInterviews] = useState([])
    const [countMatrix, setCountMatrix] = useState([])
    const [loading, setLoading] = useState(false)

    // invite module
    const [inviteInterviewModal, setInviteInterviewModal] = useState(false)
    const [interviewId, setInterviewId] = useState('')

    const interviewList = async () => {
        const url = '/ai_interviews/list'
        const responce = await makeRequest(url, 'get', {}, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                setInterviews(responce.interviews)
                setCountMatrix(responce.grouped_data)
                setLoading(true)
            },
        })
    }

    useEffect(() => {
        interviewList()
    },[])
    useEffect(() => {
        interviewList()
    },[interviewId])
    
    return (
        <>
          <div className="container-fluid ai-containter">
                <div className="d-flex justify-content-between align-items-center mb-2 mt-5 pb-3 border-bottom">
                    <div className="d-inline-flex align-items-center">
                        { prop?.organization && prop?.organization?.image_url && prop?.organization?.image_url != null &&
                            <img
                                alt={ prop.organization.name} src={ prop.organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ prop.organization.name}</p>
                            <a href={ prop.organization.website_url} target='_blank' className="text-decoration-underline">{ prop.organization.website_url }</a>
                        </div>
                    </div>

                    <div>
                        <a href='#' className="d-inline-flex" onClick={(e)=> setInterviewModalShow(true)}>
                            Create AI Interview
                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(70,146,221,1)" style={{ width: '24px', height: '24px', marginLeft: '12px' }}><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>
                        </a>
                        {/* <a href='#' className="d-inline-flex ml-4 ml-3" onClick={(e)=> setInviteInterviewModal(true)}>
                            Invite Candidates
                        </a> */}
                    </div>
                </div>
                { (interviews.length == 0 && loading) &&
                    <div className="mb-2 pb-3" style={{marginTop: '6%'}}>
                            <div style={{textAlign: 'center'}}>
                                <img src={NoInterview} alt="No AI Interview Found" height={400} width={400} />
                            </div>
                     </div>
                }
                { interviews.length > 0 &&
                    <div className="mb-2 pb-3" style={{marginTop: '2%'}}>
                        <table className='table ai-table'>
                            <thead>
                                <tr>
                                    <th>Interview Title</th>
                                    <th>Interview Type</th>
                                    <th>Job Title</th>
                                    <th className='td-center'>Invitations Sent</th>
                                    <th className='td-center'>Completed Interview</th>
                                    <th>Created On</th>
                                </tr>
                            </thead>
                            <tbody>
                                { interviews.map( (val, key) => (
                                    <tr>
                                        <td><a href={`/ai_interviews/${val.id}/detail`} className='theme-font-color'>{val.interview_title}</a></td>
                                        <td>{val.interview_type}</td>
                                        <td>{val.job.name}</td>
                                        <td className='td-center'> {(countMatrix[val.id]) ? <a href={`/ai_interviews/${val.id}/invites`} > { countMatrix[val.id]['total']}</a> : 0 } </td>
                                        <td className='td-center'>{(countMatrix[val.id]) ? ((countMatrix[val.id]['statuses']['Completed']) ? <a href={`/ai_interviews/${val.id}/invites`} > { countMatrix[val.id]['statuses']['Completed']}</a> : 0 ): 0 }</td>
                                        <td>{moment(val.created_at).format('DD MMM-YYYY')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            {interviewModalShow &&
                <InterviewCreatePage
                    interviewModalShow={interviewModalShow}
                    setInterviewModalShow={setInterviewModalShow}
                    jobs={prop.jobs}
                    jobSkills={prop.job_skills}
                    currentUser={prop.currentUser}
                    isInterviewCreated={prop.isInterviewCreated}
                    setInviteInterviewModal={setInviteInterviewModal}
                    setInterviewId={setInterviewId}
                    setShowSuccessInterviewCreate={setShowSuccessInterviewCreate}
                />
            }
            {inviteInterviewModal ? 
                <InviteInterviewPage
                inviteInterviewModal={inviteInterviewModal}
                setInviteInterviewModal={setInviteInterviewModal}
                interview_id={interviewId}
                />
                : ''
            }

            {showSuccessInterviewCreate &&
                <SweetAlert
                    success // This makes it a success alert
                    title="Success!"
                    onConfirm={() => {setShowSuccessInterviewCreate(false); setInviteInterviewModal(true)}} // Custom function for "Invite Candidate"
                    onCancel={() => (setShowSuccessInterviewCreate(false))} // Custom function for "Not Now"
                    confirmBtnText="Invite Candidates"
                    cancelBtnText="Not Now"
                    showCancel
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "#bd1d23", color: "white", borderColor: '#bd1d23', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                >
                <div>
                  <h5 style={{fontWeight: 400}}>
                    Your AI interview has been successfully created.
                    Please invite candidates to participate in the interview.
                  </h5>
                </div>
              </SweetAlert>
            }
     </>
    )
}

export default Index