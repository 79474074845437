import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import ProfileAvatarEmployer from '../../common/ProfileAvatar/ProfileAvatarEmployer'
import RecruiterOrganization from '../../pages/RecruiterOrganization/RecruiterOrganization'
import styles from './styles/Navbar.module.scss'
import styleNew from './styles/NavbarEmployer.scss'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Signup from './Signup/Signup'
import { getOrganizationFavorite } from '../../api/recruiter.api'
import feather from 'feather-icons'
import Util from '../../../utils/util'
import { Button, Modal, Row } from 'react-bootstrap'

const NavbarRecruiter = ({
    isAdmin,
    isAgency,
    isAgencyAdmin,
    isSales,
    canPeopleSearch,
    canPeopleIntake,
    canJobView,
    userId,
    isTalent,
    isEmployer,
    isRecruiter,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    url,
    isTeamOwner,
    isFinancer,
    base_url,
    isTextMessenger,
    host_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const [recruiterOrganizations, setRecruiterOrganizations] = useState([])
    const [recruiterOrganizationFavs, setRecruiterOrganizationFavs] = useState([])
    const [teamOwner, setTeamOwner] = useState([])
    const userBelongsToCT = organization?.name === window.ch_const.ct_org_name
    const userFromAgency = (organization?.agency_id != 0) ? true : false
    const has_commission_recruiter = (currentUser?.is_commission_recruiter == 'yes') ? true : false
    const subOrgBelongsToCT =
        memberOrganization?.name === window.ch_const.ct_org_name
    const fetchOrganization = async () => {
        try {
            const response = await getOrganizationFavorite();
            setRecruiterOrganizations(response);    
        } catch (error) {
            console.log(error);
        }
    };
    const [isInterviewCreatedBefore, setIsInterviewCreatedBefore] = useState(false)

    useEffect(() => {
        if (isRecruiter) {
            fetchOrganization();
        }
    }, []);


    const handleChangeOrganization = async (organization_id) => {
        const url = '/change_recruiter_organization'

        const payload = {
            organization_id: organization_id,
            user_id: userId,
        }

        const response = await makeRequest(url, 'put', payload, {
            contentType: 'application/json',
            loadingMessage: 'changing organization...',
            createResponseMessage: (response) => {
                return response.message
            },
            onSuccess: () => {
                setTimeout(() => {
                    window.location.href = '/jobs'
                }, 1200)
            },
        })
    }

    const onAiInterviewClick = async() => { 
        const url = '/ai_interviews/check_ai_interview_exist'
        await makeRequest(url, 'get', {}, {
            contentType: 'application/json',
            loadingMessage: '...',
            createResponseMessage: (response) => {
                return response.message
            },
            onSuccess: (responce) => {
                if(responce.data.isAiInterview){
                    window.location.href = '/ai_interviews/create'
                }else{
                    setIsInterviewCreatedBefore(true)
                }
            },
        })
    }

    const onCreatInterviewClick = () => {
        window.location.href = '/ai_interviews/create?show=1'
    }
    
    const authLinks = (
        <>  
            <Nav.Link
                
                className={`${styles.navbarLink} ${currentPathname === 'ai_interviews/create' ||
                        currentPathname === '/ai_interviews/create'|| currentPathname.includes('ai_interviews') == true
                        ? 'activeLinkColor'
                        : 'linkMenu'
                    }`}
                onClick={()=>onAiInterviewClick()}
            >
                AI Interviewer
            </Nav.Link>
            {(isRecruiter) && (
                <Nav.Link
                    href="/jobs"
                    className={`${styles.navbarLink} ${currentPathname === '/jobs' ||
                            currentPathname === '/jobs/new'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Jobs Posted
                </Nav.Link>
            )}

            {(isRecruiter) && canPeopleSearch && !has_commission_recruiter && (
                <Nav.Link
                    href="/people_searches/new"
                    className={`${styles.navbarLink} ${currentPathname === '/people_searches/new'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Candidate Search
                </Nav.Link>
            )}
              
             {(isRecruiter) && (
                <Nav.Link
                    href="/interview_scheduled"
                    className={`${styles.navbarLink} ${currentPathname === '/interview_scheduled'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Interviews Scheduled
                </Nav.Link>
            )}

            {isRecruiter && (userBelongsToCT || subOrgBelongsToCT) && (
                <Nav.Link
                    href="/job/cardinal_jobs"
                    className={`${styles.navbarLink} ${currentPathname === '/job/cardinal_jobs'
                        ? 'activeLinkColor'
                        : 'linkMenu'
                    }`}
                >
                    Cardinal Jobs
                </Nav.Link>
            )}

            {isRecruiter && !has_commission_recruiter && (userBelongsToCT || subOrgBelongsToCT) && (
                <Nav.Link
                    href={`/users/${currentUser.id}/timesheet`}
                    className={`${styles.navbarLink} ${currentPathname === `/users/${currentUser.id}/timesheet`
                             ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Time Entry
                </Nav.Link>
            )}

        </>
    )
    
    
    return (
        <>
            <Navbar expand="lg" sticky="top" style={{zIndex: 1040 }}>
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>CardinalTalent</span>{' '}
                    <span style={{ fontSize: '0.9rem' }}>AI Interviewer</span>
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className='mr-3'>
                        {/* <Nav.Link href="#employee">Employers</Nav.Link>
                        <Nav.Link href="#talent">Talent</Nav.Link>
                        <Nav.Link  className='activeLinkColorTop' style={{color: '#000'}}>Recruiters</Nav.Link> */}
                    </Nav>
                    {isRecruiter && (
                        <RecruiterOrganization
                            organization={organization}
                            recruiterOrganizations={recruiterOrganizations}
                            handleChangeOrganization={handleChangeOrganization}
                        />
                    )}
                </Navbar.Collapse>
                     <ProfileAvatarEmployer
                        profileAvatar={avatar_url}
                        userId={userId}
                        organization_id={organization ? organization.id : ''}
                        isEmployer={isEmployer}
                        currentUser={currentUser}
                        userBelongsToCT={userBelongsToCT}
                        subOrgBelongsToCT={subOrgBelongsToCT}
                        isTalent={isTalent}
                    />
            </Navbar>

            <Navbar expand="lg" variant="dark" className="text-white dark-blue-nav" sticky="top">
                

                <Navbar.Collapse id="basic-navbar-nav-second" className="justify-content-end">
                    <Nav>
                        {userId && isRecruiter && authLinks}
                        <NavDropdown title="Analytics" id="basic-nav-dropdown" style={{marginLeft: '20px'}}>
                            <NavDropdown.Item  href="/analytic/recruiter_jobs_analytics"
                                                className={`navbarLinkCust ${currentPathname === '/analytic/recruiter_jobs_analytics'
                                                ? 'activeLinkColor'
                                                : 'linkMenu'
                                                }`}
                            >Jobs</NavDropdown.Item>
                            <NavDropdown.Item href="/analytic/submitted_candidates_analytic"
                                className={`navbarLinkCust ${currentPathname === '/analytic/submitted_candidates_analytic'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Submitted Candidate</NavDropdown.Item>
                            <NavDropdown.Item  href="/analytic/incoming_mail_dashboard"
                                className={`navbarLinkCust ${currentPathname === '/analytic/incoming_mail_dashboard'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Incoming Mail Dashboard</NavDropdown.Item>

                            <NavDropdown.Item  href="/analytic/automation_mail_dashboard"
                                className={`navbarLinkCust ${currentPathname === '/analytic/automation_mail_dashboard'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Automation Email Dashboard</NavDropdown.Item>
                            
                            <NavDropdown.Item  href="/auto_candidate_submission"
                                className={`navbarLinkCust ${currentPathname === '/auto_candidate_submission'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Automation Text Dashboard</NavDropdown.Item>

                            <NavDropdown.Item  href="/analytic/automation_analytic"
                                className={`navbarLinkCust ${currentPathname === '/analytic/automation_analytic'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Automation Analytics</NavDropdown.Item>
                            {currentUser?.agency_id === 0 && !has_commission_recruiter && 

                                <NavDropdown.Item  href="/analytic/organization_onboard"
                                    className={`navbarLinkCust ${currentPathname === '/analytic/organization_onboard'
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                >
                                    Client Onboarding</NavDropdown.Item>
                            }
                            {(isRecruiter) && (userBelongsToCT || subOrgBelongsToCT) && !has_commission_recruiter &&   

                                <NavDropdown.Item  href="/analytic/cross_submissions_analytic"
                                    className={`navbarLinkCust ${currentPathname === '/analytic/cross_submissions_analytic'
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                >
                                    Cross Submissions</NavDropdown.Item>
                            }
                            {(isRecruiter) && (isTeamOwner) && !has_commission_recruiter && 
                             <>
                                    <NavDropdown.Item  href="/analytic/recruiter_teams_analytic"
                                    className={`navbarLinkCust ${currentPathname === '/analytic/recruiter_teams_analytic'
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                     >
                                    Team</NavDropdown.Item>

                                    <NavDropdown.Item  href="/analytic/recruiters_assignments_analytic"
                                    className={`navbarLinkCust ${currentPathname === '/analytic/recruiters_assignments_analytic'
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                     >
                                    Recruiters Assignments</NavDropdown.Item>

                                    <NavDropdown.Item  href="/analytic/in_mails"
                                    className={`navbarLinkCust ${currentPathname === '/analytic/in_mails'
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                     >
                                    InMails</NavDropdown.Item>
                             </>
                            }
                         </NavDropdown>
                        <NavDropdown title="Tasks" id="basic-nav-dropdown-task" style={{marginLeft: '20px'}}>
                            <NavDropdown.Item  href="/in_mails"
                                                className={`navbarLinkCust ${(currentPathname == '/in_mails' || currentPathname.includes('in_mails') == true)
                                                    ? 'activeLinkColor'
                                                    : 'linkMenu'
                                                }`}
                            >InMails</NavDropdown.Item>
                            <NavDropdown.Item  href="/job_leads"
                                                className={`navbarLinkCust ${(currentPathname == '/job_leads' || currentPathname.includes('job_leads') == true)
                                                    ? 'activeLinkColor'
                                                    : 'linkMenu'
                                                }`}
                            >Review Leads</NavDropdown.Item>
                            <NavDropdown.Item href="/job_process_leads"
                                className={`navbarLinkCust ${(currentPathname === '/job_process_leads'  || currentPathname.includes('job_process_leads') == true)
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Process Leads</NavDropdown.Item>
                            {currentUser.designation == "marketer" && 
                                <NavDropdown.Item href="/email_datas"
                                className={`navbarLinkCust ${(currentPathname === '/email_datas'  || currentPathname.includes('email_datas') == true)
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                                >
                                Email Data</NavDropdown.Item>
                            }
                        </NavDropdown>
                        {(isRecruiter) && (userBelongsToCT || subOrgBelongsToCT) && !has_commission_recruiter && 
                         <>
                            <NavDropdown title="Sourcing" id="basic-nav-dropdown-task" style={{marginLeft: '20px'}}>
                                <NavDropdown.Item href="/sourcing_schedules"
                                    className={`navbarLinkCust ${(currentPathname === '/sourcing_schedules'  || currentPathname.includes('sourcing_schedules') == true)
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                    >
                                    Sourcing</NavDropdown.Item>
                            </NavDropdown>
                         </>
                        }
                        {isRecruiter && (userBelongsToCT || subOrgBelongsToCT) &&
                            <Nav.Link
                                href={`/weekly_referral`}
                                className={`${styles.navbarLink} ${currentPathname === `/weekly_referral`
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Referral
                            </Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Modal
                show={isInterviewCreatedBefore}
                onHide={() => setIsInterviewCreatedBefore(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                id="custom-dialog-popup-to-header"
            >
            <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-between">
                            <h5 className="font-weight-bold mb-3">AI Interview</h5>
                            <span onClick={() => setIsInterviewCreatedBefore(false)} role="button">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </span>
                        </div>
                        <Row className='d-flex' style={{padding: '4%'}}>
                            <p>It looks like there are no AI interviews currently created.</p>
                            <p>Would you like to create a new AI interview now?</p>
                            <p>Click the button below to set up a new AI interview for your candidates</p>
                        </Row>
                                
                        <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                            <Button variant="outline-primary" className="mr-3" onClick={() => setIsInterviewCreatedBefore(false) }>Cancel</Button>
                            <Button variant="primary" onClick={() => onCreatInterviewClick()} className='btn-pk-color'>Create AI Interview</Button>
                        </div>
                    </div>           
            </Modal.Body>
            </Modal>
        </>
    )
}

export default NavbarRecruiter
