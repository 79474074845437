import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../styles/AiInterview.scss'
import { capitalize } from '../../../../utils'
import ResumeIcon from '../../../../../assets/images/icons/resume-icon'
import { Row,Col,Spinner } from 'react-bootstrap'
import moment from 'moment'
import DataTable from "react-data-table-component-footer"
import { DisplayPaginationAI } from '../../Analytic/styles/AnalyticPage.styled'
import   '../styles/DatatableAICustom.scss'
import InviteInterviewPage from './InviteInterviewPage'


const InvitesListPage = ({organization, interview, job, per_page}) => {
    const [loading, setLoading] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalDatas,setTotalDatas] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [sortField, setSortField] = useState('')
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(per_page)
    const [sortDirection, setSortDirection] = useState('')
    const [inviteInterviewModal, setInviteInterviewModal] = useState(false)
   
    const columns = [
        { name: 'No.',
          selector: row => row.id,
          maxWidth: '6%',
          minWidth: '6%'
        },
        { name: 'Candidate Name',
          selector: row => row.name,
          sortable: false,
          sortField: 'candidate_name',
          maxWidth: '25%',
          minWidth: '25%'
        },
        { name: 'Candidate Email',
          selector: row => row.email,
          sortable: false,
          sortField: 'candidate_email',
          maxWidth: '25%',
          minWidth: '25%'
        },
        { name: 'Invited On',
          selector: row => row.created_at,
          sortable: false,
          sortField: 'date',
          maxWidth: '15%',
          minWidth: '15%'
        },
        { name: 'Status',
          selector: row => row.status,
          sortable: false,
          sortField: 'status',
          maxWidth: '15%',
          minWidth: '15%'
        },
        {
            name: 'Report',
            selector: row => row.report,
            editable: false,
            type: 'text',
            maxWidth: '14%',
            minWidth: '14%'
        }
    ]
    // Fetch existing interview question 
    const getInterviewInvites = async (id) =>{
        setCandidates([])
        const url =`/ai_interviews/${id}/invites.json?page=${activePage}&sortField=${sortField}&sortDirection=${sortDirection}`
        const response = await axios.get(url).then(res=>res).catch(error=>console.log(error))
        console.log(response.data)
        if(response?.status){
            setCandidates(response.list)
            setTotalCount(response.data.total_count)
            let sr_no = (activePage - 1) * response.data.per_page
            setCandidates(response?.data?.list.map((data,key)=> (sr_no= sr_no + 1,{
              id: sr_no,
              name: capitalize(data?.candidate_name),
              email: data?.candidate_email,
              created_at: moment(data.updated_at).format('MMM DD, YYYY'),
              status: data?.status,
              report: (data?.status == 'Completed') ?  <a href={`/ai_interviews/${data.id}/report`}><img src={ResumeIcon} className='align-items-center' style={{cursor:'pointer'}}/></a> : ''
            })))
            setPageCount(response.data.total_pages)
            setStartRecord((activePage - 1) * response.data.per_page)
            setCurrentCounts(response.data.current_counts)
            setLoading(false)
        }
    }

    useEffect (() => {
        getInterviewInvites(interview.id)
    },[activePage,sortField,sortDirection])
  
    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <>
            <div className="container-fluid ai-containter">
                <div className="d-flex justify-content-between align-items-center pb-3 mt-5 ">
                    <div className="d-inline-flex align-items-center">
                        { organization && organization?.image_url && organization?.image_url != null &&
                            <img
                                alt={ organization.name} src={ organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ organization.name}</p>
                            <a href={ organization.website_url} target='_blank' className="text-decoration-underline">{ organization.website_url }</a>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow">
                    <div className="d-flex justify-content-between align-items-center">
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb breadcrumb-ai">
                                <li className="breadcrumb-item"><a href="/ai_interviews/create">AI Interviews</a></li>
                                <li className="breadcrumb-item"> <a href={`/ai_interviews/${interview.id}/detail`}>{ interview.interview_title }</a></li>
                                <li className="breadcrumb-item" aria-current="page">Invited Candidates</li>
                            </ol>
                        </nav>
                        <div>
                            <a className='' href="#" onClick={(e)=> setInviteInterviewModal(true)}>Invite Candidate</a>
                        </div>
                    </div>
                </div>
                <div className="justify-content-between align-items-right">
                <Row className="w-100 mt-5" >
                    <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end pl-3'>
                        {totalCount > 0 && loading == false &&
                            <DisplayPaginationAI>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPaginationAI>
                        }
                    </Col>
                    <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                        <div style={{display: 'flex'}}>
                           
                        </div>
                    </Col>
                </Row>
                <DataTable
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={candidates}
                    progressPending={loading}
                    progressComponent={ <div className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setActivePage(page)}
                />
                </div>
            </div>

            {inviteInterviewModal ? 
                <InviteInterviewPage
                inviteInterviewModal={inviteInterviewModal}
                setInviteInterviewModal={setInviteInterviewModal}
                interview_id={interview.id}
                />
                : ''
            }
        </>
      );

}
export default InvitesListPage