// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".BulkCandiadateUploadPage-module__container___2vFvc{background:#ffffff;box-shadow:0px 4px 25px rgba(0,0,0,0.25);border-radius:50px;padding:50px 65px;width:90%}@media (max-width: 500px){.BulkCandiadateUploadPage-module__container___2vFvc{background:#ffffff;box-shadow:0px 4px 25px rgba(0,0,0,0.25);border-radius:50px;padding:25px 33px;width:80%}}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20241014121702/app/javascript/components/pages/BulkCandidateUploadPage/styles/BulkCandiadateUploadPage.module.scss"],"names":[],"mappings":"AAEA,oDACI,kBAAmB,CACnB,wCAA4C,CAC5C,kBAAmB,CACnB,iBAAkB,CAElB,SAAU,CACb,0BAGG,oDACI,kBAAmB,CACnB,wCAA4C,CAC5C,kBAAmB,CACnB,iBAAkB,CAElB,SAAU,CACb","file":"BulkCandiadateUploadPage.module.scss","sourcesContent":["@import '../../../../styles/var';\n\n.container {\n    background: #ffffff;\n    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);\n    border-radius: 50px;\n    padding: 50px 65px;\n\n    width: 90%;\n}\n\n@media (max-width: 500px) {\n    .container {\n        background: #ffffff;\n        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);\n        border-radius: 50px;\n        padding: 25px 33px;\n\n        width: 80%;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "BulkCandiadateUploadPage-module__container___2vFvc"
};
module.exports = exports;
